cs.ns('app.ui.composite.osc');
app.ui.composite.osc.view = cs.clazz({
    extend:   app.fw.abstract.view,
    dynamics: {
        markupName: 'appOSC'
    },
    protos:   {

        ready() {
            Mousetrap.bind('space', () => {
                if (cs(this).value('global:state:paused'))
                    cs(this).value('event:play', true)
                else
                    cs(this).value('event:pause', true)
            });
            Mousetrap.bind('left', () => {
                cs(this).value('event:backward', true)
            })
            Mousetrap.bind('right', () => {
                cs(this).value('event:forward', true)
            })
        }
    }
});
